var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.popularProductsWithOffers && _vm.popularProductsWithOffers.length)?_c('SwContainer',_vm._b({class:( _obj = {}, _obj[_vm.$style['block']] = true, _obj[_vm.$style['block_inverted']] = _vm.inverted, _obj )},'SwContainer',{
        stretched: _vm.stretched,
        paddingY: _vm.paddingY,
        bgColor: _vm.bgColor,
        bgOpacity: _vm.bgOpacity,
        bgImage: _vm.bgImage,
    },false),[_c('h2',{class:[_vm.$style['block__title'], 'sw-title'],domProps:{"innerHTML":_vm._s(_vm.title)}}),_vm._v(" "),_c('div',{class:[_vm.$style['block__carousel'], _vm.$style['carousel']]},[_c('CarouselRoot',{key:_vm.update,ref:"carousel",staticStyle:{"width":"100%","height":"440px","max-width":"1056px"},attrs:{"settings":_vm.hooperSettings}},_vm._l((_vm.popularProductsWithOffers),function(product,index){return _c('CarouselSlide',{key:index,attrs:{"index":index}},[(product.offers.length)?_c('ProductItem',{key:index,class:_vm.$style['carousel__item'],attrs:{"name":product.name,"slug":product.slug,"offers":product.offers,"like":product.like,"image":product.imageUrl,"labels":product.labels,"inverted":_vm.inverted,"disabled":product.disabled,"type":product.type,"light":"","small":""}}):_vm._e()],1)}),1),_vm._v(" "),(_vm.controlsVisible)?[_c('SwButton',{class:[_vm.$style['carousel__navigation-button'], _vm.$style['carousel__navigation-button_left']],attrs:{"inverted":_vm.inverted,"transparent":_vm.inverted,"small":""},on:{"click":function($event){return _vm.$refs.carousel.slidePrev()}},scopedSlots:_vm._u([{key:"leading-icon",fn:function(){return [_c('SvgSpriteArrowLeft')]},proxy:true}],null,false,4229751787)}),_vm._v(" "),_c('SwButton',{class:[_vm.$style['carousel__navigation-button'], _vm.$style['carousel__navigation-button_right']],attrs:{"inverted":_vm.inverted,"transparent":_vm.inverted,"small":""},on:{"click":function($event){return _vm.$refs.carousel.slideNext()}},scopedSlots:_vm._u([{key:"leading-icon",fn:function(){return [_c('SvgSpriteArrowRight')]},proxy:true}],null,false,4051955344)})]:_vm._e()],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }