<template>
    <div
        :class="{
            'sw-text': true,
            [$style['name']]: true,
            [$style['name_inverted']]: inverted
        }"
    >
        {{ title }}
    </div>
</template>

<script>
export default {
  name: 'ProductName',

  props: {
    title: {
      type: String,
      default: ''
    },

    inverted: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.name {
  display: -webkit-box !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: @black-700;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media screen and (max-width: 487.5px) {
    font-size: 14px;
    line-height: 20px;
  }

  &_inverted {
    color: #FFFFFF;
  }
}
</style>
