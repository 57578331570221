<template>
    <SwContainer
        v-if="popularProductsWithOffers && popularProductsWithOffers.length"
        :class="{
            [$style['block']]: true,
            [$style['block_inverted']]: inverted
        }"
        v-bind="{
            stretched,
            paddingY,
            bgColor,
            bgOpacity,
            bgImage,
        }"
    >
        <h2
            :class="[$style['block__title'], 'sw-title']"
            v-html="title"
        />

        <div :class="[$style['block__carousel'], $style['carousel']]">
            <CarouselRoot
                ref="carousel"
                :key="update"
                :settings="hooperSettings"
                style="width: 100%; height: 440px; max-width: 1056px;"
            >
                <CarouselSlide
                    v-for="(product, index) in popularProductsWithOffers"
                    :key="index"
                    :index="index"
                >
                    <ProductItem
                        v-if="product.offers.length"
                        :key="index"
                        :name="product.name"
                        :slug="product.slug"
                        :offers="product.offers"
                        :like="product.like"
                        :image="product.imageUrl"
                        :labels="product.labels"
                        :class="$style['carousel__item']"
                        :inverted="inverted"
                        :disabled="product.disabled"
                        :type="product.type"
                        light
                        small
                    />
                </CarouselSlide>
            </CarouselRoot>

            <template v-if="controlsVisible">
                <SwButton
                    :class="[$style['carousel__navigation-button'], $style['carousel__navigation-button_left']]"
                    :inverted="inverted"
                    :transparent="inverted"
                    small
                    @click="$refs.carousel.slidePrev()"
                >
                    <template #leading-icon>
                        <SvgSpriteArrowLeft />
                    </template>
                </SwButton>

                <SwButton
                    :class="[$style['carousel__navigation-button'], $style['carousel__navigation-button_right']]"
                    :inverted="inverted"
                    :transparent="inverted"
                    small
                    @click="$refs.carousel.slideNext()"
                >
                    <template #leading-icon>
                        <SvgSpriteArrowRight />
                    </template>
                </SwButton>
            </template>
        </div>
    </SwContainer>
</template>

<script>
import 'hooper/dist/hooper.css'

import {
  Hooper as CarouselRoot,
  Slide as CarouselSlide
} from 'hooper'
import SvgSpriteArrowLeft from './PopularProductList/sprite-arrow-left.svg'
import SvgSpriteArrowRight from './PopularProductList/sprite-arrow-right.svg'
import ProductItem from '@@/website/components/common/ProductItem'

import { GET_PRODUCTS_GOODS } from '@@/graphQL/web'

export default {
  name: 'PopularProductList',

  components: {
    CarouselRoot,
    CarouselSlide,
    ProductItem,
    SvgSpriteArrowLeft,
    SvgSpriteArrowRight
  },

  props: {
    pivotId: {
      type: [Number, String],
      default: null
    },

    title: {
      type: String,
      default: 'Популярные товары'
    },

    labels: {
      type: Array,
      default: () => []
    },

    stretched: {
      type: Boolean,
      default: false
    },

    paddingY: {
      type: String,
      default: 's'
    },

    bgColor: {
      type: String,
      default: '#FFFFFF'
    },

    bgImage: {
      type: String,
      default: ''
    },

    bgOpacity: {
      type: String,
      default: '0%'
    },

    inverted: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    productGoods: null,
    update: 0
  }),

  apollo: {
    productGoods: {
      query: GET_PRODUCTS_GOODS,
      variables () {
        return {
          siteCode: this.$store.state.siteCode,
          labels: this.labels,
          pivot_id: this.pivotId
        }
      }
    }
  },

  computed: {
    popularProductsWithOffers () {
      return this.productGoods
        ? this.productGoods.filter(product => product.offers.length > 0)
        : []
    },

    controlsVisible () {
      // @TODO: Здесь проверяется, что находимся в конструкторе сайта. Переделать на provide/inject
      return !this.$route.name.includes('constructor') &&
        this.popularProductsWithOffers &&
        this.popularProductsWithOffers.length > 4
    },

    hooperSettings () {
      return {
        breakpoints: this.hooperBreakpoints,
        infiniteScroll: this.popularProductsWithOffers.length > 4,
        itemsToShow: Math.min(this.popularProductsWithOffers.length, 4),
        keysControl: false,
        mouseDrag: false,
        shortDrag: false,
        transition: 400,
        wheelControl: false
      }
    },

    hooperBreakpoints () {
      return {
        0: {
          itemsToShow: 1.3,
          mouseDrag: true,
          autoPlay: true
        },
        400: {
          itemsToShow: 1.7,
          mouseDrag: true,
          autoPlay: true
        },
        500: {
          itemsToShow: 2,
          mouseDrag: true,
          autoPlay: true
        },
        600: {
          itemsToShow: 2.5,
          mouseDrag: true,
          autoPlay: true
        },
        812: {
          itemsToShow: 3,
          shortDrag: false,
          mouseDrag: false
        },
        1152: {
          itemsToShow: this.popularProductsWithOffers.length >= 4 ? 3.92 : this.popularProductsWithOffers.length,
          mouseDrag: false,
          touchDrag: false
        }
      }
    }
  },

  mounted () {
    if (this.$refs.carousel) {
      this.$refs.carousel.updateWidth()
    }
    this.update++
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.block {
  @media screen and (max-width: @bp-mobile-small) {
    padding-right: 0;
  }

  &__title {
    @media screen and (max-width: @bp-mobile-small) {
      margin-left: 16px;
      margin-right: 16px;
    }
  }

  &_inverted &__title {
    color: #FFFFFF;
  }

  &__carousel {
    margin: auto;
    & :global(.sw-image-placeholder) {
      height: 100% !important
    }
  }

  & :global(.sw-container__content) {
    position: relative;
  }
}

:global(.hooper) {
  margin: 0;
}

:global(.hooper-list) {
  padding-top: 12px;
  height: auto;
  padding-left: 1px;
}

:global(.hooper-indicators) {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  bottom: 32px;
  width: 100%;
  z-index: 1;

  & button {
    border-radius: 2px;
    width: 8px;
    height: 8px;
    padding: 0;
    margin: 4px;
    background: var(--sw-color-main-200);
    border: none;
  }

  :global(.is-active) {
    background: var(--sw-color-main-500) !important;
  }
}

.carousel {
  width: 100%;
  position: relative;

  :global(.hooper-slide) {
    flex: 1 0 auto;
    display: flex;
  }

  &__navigation-button {
    position: absolute;
    transform: translateY(-50%);
    margin-top: -52px;
    top: 50%;
    z-index: 1;
    @media screen and (max-width: @bp-tablet) {
      min-width: auto;
    }
    @media screen and (max-width: @bp-mobile-small) {
      display: none !important;
    }

    &_left {
      left: -82px;
      @media screen and (max-width: @bp-tablet) {
        left: -20px;
      }
    }

    &_right {
      right: -82px;
      @media screen and (max-width: @bp-tablet) {
        right: -20px;
      }
    }
  }

  &__item {
    display: flex;
    padding: 0;
    margin: 0;
    @media screen and (max-width: @bp-tablet) {
      max-width: 220px;
    }
    @media screen and (max-width: @bp-mobile-small) {
      padding: 0 8px;
    }
    [class*='product__name'] {
      min-height: 48px;
    }
  }

  [class*='product__name'] {
    flex-grow: 0;
    margin: 16px 0 8px 0;
    padding: 0;
  }

  [class*='product__price'],
  [class*='product__buy'] {
    padding: 0
  }
}
</style>
