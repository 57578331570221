<template>
    <div
        :class="{
            'sw-text': true,
            [$style['price']]: true,
            [$style['price_bold']]: bold,
            [$style['price_bonus']]: bonus,
            [$style['price_large']]: large,
            [$style['price_inverted']]: inverted
        }"
    >
        <template v-if="currency && !bonus">
            {{ $n(value, { style: 'currency', currency: currency }) }}
        </template>
        <template v-else>
            {{ value }}
        </template>
    </div>
</template>

<script>
export default {
  name: 'ProductPrice',

  props: {
    value: {
      type: Number,
      default: 0
    },

    bold: {
      type: Boolean,
      default: false
    },

    bonus: {
      type: Boolean,
      default: false
    },

    large: {
      type: Boolean,
      default: false
    },

    inverted: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    currency () {
      return this.$store.state.settings.default_currency
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.price {
  font-size: 16px;
  font-variant: lining-nums;
  line-height: 24px;
  white-space: nowrap;

  &_bold {
    font-weight: 500;
  }

  &_bonus {
    color: @purple-400;
  }

  &_large {
    font-size: 24px;
  }

  &_inverted {
    color: #FFFFFF;
  }
}
</style>
