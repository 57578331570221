<template>
    <PopularProductList
        :pivot-id="pivotId"
        :title="config.heading_text"
        :text="config.text_text"
        :labels="config.show_products_with_tag || []"
        :stretched="config.full_height"
        :padding-y="config.block_padding_y"
        :bg-color="config.type_background === 'color' ? config.background_color : config.background_image_color"
        :bg-image="config.background_url_image"
        :bg-opacity="config.type_background === 'color' ? config.background_opacity : config.background_image_opacity"
        :inverted="config.inverted_font_color && styleKey === 'products_in_the_column'"
    />
</template>

<script>
import PopularProductList from '@@/website/components/blocks/BlockProductList/PopularProductList'

export default {
  name: 'BlockProductList',

  components: {
    PopularProductList
  },

  props: {
    pivotId: {
      type: [Number, String],
      default: null
    },

    styleKey: {
      type: String,
      default: ''
    },

    config: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    buttonLink () {
      return this.$store.state.admin
        ? `/${this.$store.state.siteCode}/preview${this.config.button_button_link}`
        : this.config.button_button_link
    }
  }
}
</script>
