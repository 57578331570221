<template>
    <div :class="$style['list']">
        <div
            v-for="(label, index) in labels"
            :key="index"
            :class="{
                'sw-text': true,
                [$style['label']]: true,
                [$style['label_roundings_none']]: $simlaweb.settings.roundings === ROUNDINGS.NONE,
                [$style['label_roundings_small']]: $simlaweb.settings.roundings === ROUNDINGS.SMALL,
                [$style['label_roundings_large']]: $simlaweb.settings.roundings === ROUNDINGS.LARGE
            }"
            :style="styles(COLORS[label] || 'black')"
        >
            {{ titles[label] || label }}
        </div>
    </div>
</template>

<script>
import palette from '@@/framework/palette'
import { ROUNDINGS } from '@@/framework/types'

const LABELS = {
  NOVELTY: 'novelty',
  POPULAR: 'popular',
  RECOMMENDED: 'recommended',
  STOCK: 'stock'
}

const COLORS = {
  [LABELS.NOVELTY]: 'red',
  [LABELS.POPULAR]: 'orange',
  [LABELS.RECOMMENDED]: 'blue',
  [LABELS.STOCK]: 'green'
}

export default {
  name: 'ProductLabelList',

  props: {
    labels: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    COLORS: () => COLORS,
    ROUNDINGS: () => ROUNDINGS,

    titles () {
      return {
        [LABELS.NOVELTY]: this.$t('labels_novelty'),
        [LABELS.POPULAR]: this.$t('labels_popular'),
        [LABELS.RECOMMENDED]: this.$t('labels_recommended'),
        [LABELS.STOCK]: this.$t('labels_stock')
      }
    },

    styles () {
      /**
       * @param {keyof ColorPalette} color
       */
      return color => ({
        '--sw-color-main-800': palette.get(color, 800),
        '--sw-color-main-700': palette.get(color, 700),
        '--sw-color-main-600': palette.get(color, 600),
        '--sw-color-main-500': palette.get(color, 500),
        '--sw-color-main-400': palette.get(color, 400),
        '--sw-color-main-300': palette.get(color, 300),
        '--sw-color-main-200': palette.get(color, 200),
        '--sw-color-main-100': palette.get(color, 100),
        '--sw-color-main-000': palette.get(color, 0)
      })
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.label {
  display: inline-block;
  padding: 1px 11px;
  background-color: var(--sw-color-main-100);
  border: 1px solid var(--sw-color-main-500);
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  color: var(--sw-color-main-500);
  margin: 0 8px 4px 0;

  &_roundings_none { border-radius: 0; }
  &_roundings_small { border-radius: 6px; }
  &_roundings_large { border-radius: 32px; }
}
</style>
